<template>
    <div class="home d-flex" v-resize="onResize">
        <v-dialog v-model="profileDialog.isOpen" width="400" :persistent="true">
            <v-card>
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0">
                        {{ profileDialog.title }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="white"
                        icon
                        @click="profileDialog.isOpen = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>

                <v-card-actions>
                    <v-row>
                        <v-col cols="12" style="display: flex">
                            <v-text-field
                                v-model="profileNameInput"
                                label="New Name"
                                :rules="inputRules"
                                hide-details
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red"
                        text
                        @click="profileDialog.isOpen = false"
                        :loading="dialogLoading"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="orange"
                        v-if="profileDialog.mode === 'update'"
                        text
                        @click="updateProfileName()"
                        :loading="dialogLoading"
                    >
                        Update
                    </v-btn>
                    <v-btn
                        color="primary"
                        v-else
                        text
                        @click="saveProfile()"
                        :loading="dialogLoading"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card class="d-flex flex-grow-1">
            <v-container fluid class="d-flex flex-grow-1">
                <v-row dense class="d-flex flex-grow-1" style="width: 100%">
                    <v-col lg="8" md="8" xs="12" cols="12">
                        <v-card
                            class="d-flex flex-column"
                            style="height: 100%"
                            outlined
                            :data-title="
                                tutorialConstant['ib-monitor']['pageComponent'][
                                    'monitor'
                                ]['title']
                            "
                            :data-intro="
                                tutorialConstant['ib-monitor']['pageComponent'][
                                    'monitor'
                                ]['intro']
                            "
                            :data-step="
                                tutorialConstant['ib-monitor']['pageComponent'][
                                    'monitor'
                                ]['step']
                            "
                        >
                            <v-card-title
                                style="border-bottom: 1px solid #404040"
                                class="pt-1"
                            >
                                <div
                                    class="text-left"
                                    style="
                                        margin-left: 0;
                                        width: 50%;
                                        font-size: 13px;
                                        font-weight: normal;
                                    "
                                >
                                    MONITOR
                                </div>
                                <div
                                    class="text-right"
                                    style="margin-right: 0; width: 50%"
                                >
                                    <v-icon
                                        color="green"
                                        dark
                                        dense
                                        @click="startDownload"
                                        >mdi-microsoft-excel</v-icon
                                    ><download-excel
                                        id="csvExport"
                                        :fetch="fetchData"
                                        :fields="computedCSVFields"
                                        type="csv"
                                        style="display: none"
                                        :name="downloadExcelName"
                                        :escapeCsv="false"
                                    >
                                    </download-excel>
                                    <!-- <v-menu offset-y dense>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                small
                                                color="default"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                                class="ml-1"
                                            >
                                                {{
                                                    detailIntervalOptions.find(
                                                        (option) =>
                                                            option.value ===
                                                            selectedDetailInterval
                                                    ).key
                                                }}
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item
                                                v-for="item in detailIntervalOptions"
                                                :key="item.key"
                                                @click="
                                                    () => {
                                                        selectedDetailInterval =
                                                            item.value;
                                                    }
                                                "
                                            >
                                                <v-list-item-title>{{
                                                    item.key
                                                }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu> -->
                                </div>
                            </v-card-title>

                            <v-row
                                class="flex-shrink-0 flex-grow-0"
                                align="center"
                                style="margin: 0"
                            >
                                <v-col
                                    cols="3"
                                    class="pl-6"
                                    style="padding: 0"
                                    :data-title="
                                        tutorialConstant['ib-monitor'][
                                            'profileManipulation'
                                        ]['profileList']['title']
                                    "
                                    :data-intro="
                                        tutorialConstant['ib-monitor'][
                                            'profileManipulation'
                                        ]['profileList']['intro']
                                    "
                                    :data-step="
                                        tutorialConstant['ib-monitor'][
                                            'profileManipulation'
                                        ]['profileList']['step']
                                    "
                                >
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on }">
                                            <v-list-item
                                                color="primary"
                                                v-on="on"
                                                style="background: #1e1e1e"
                                            >
                                                <v-list-item-title
                                                    ><v-icon small class="mr-2"
                                                        >mdi-notebook-outline</v-icon
                                                    >{{
                                                        selectedProfile.text
                                                    }}</v-list-item-title
                                                >
                                            </v-list-item>
                                        </template>
                                        <v-list>
                                            <v-list-item
                                                v-for="(
                                                    item, index
                                                ) in allProfile"
                                                :key="index"
                                            >
                                                <v-list-item-title
                                                    @click="
                                                        UPDATE_SELECTED_PROFILE(
                                                            item
                                                        )
                                                    "
                                                >
                                                    {{ item.text }}
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="3"
                                    align="center"
                                    style="padding: 0"
                                >
                                    <CardHead
                                        :name="'Unrealized'"
                                        :value="mainBookTitle.unrealized"
                                        :description="
                                            mainBookTitleDesc.unrealized
                                        "
                                    />
                                </v-col>
                                <v-col
                                    cols="3"
                                    align="center"
                                    style="padding: 0"
                                >
                                    <CardHead
                                        :name="'Realized'"
                                        :value="mainBookTitle.realized"
                                        :description="
                                            mainBookTitleDesc.realized
                                        "
                                    />
                                </v-col>
                                <v-col
                                    cols="3"
                                    align="center"
                                    style="padding: 0"
                                >
                                    <CardHead
                                        :name="'Daily Net'"
                                        :value="mainBookTitle.dailyNet"
                                        :description="
                                            mainBookTitleDesc.dailyNet
                                        "
                                    />
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>

                            <!-- PK主表逻辑 -->
                            <v-data-table
                                dense
                                :mobile-breakpoint="0"
                                :headers="mainHeader"
                                :items="computedPKData"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :header-props="{ sortIcon: null }"
                                :custom-filter="filterTable"
                                :customSort="customSort"
                                item-key="symbol"
                                @click:row="rowClicked"
                                class="elevation-0 flex-grow-1"
                                :items-per-page="30"
                                :footer-props="{
                                    itemsPerPageOptions: [10, 20, 30],
                                    showFirstLastPage: false,
                                    'items-per-page-text': '',
                                }"
                                id="home-table"
                                :height="tableHeight"
                                fixed-header
                                v-sortable-table="{
                                    onEnd: sortPKHeadersAndUpdateTheKey,
                                }"
                                :key="anIncreasingNumberForMain"
                            >
                                <template v-slot:header.symbol="{ header }">
                                    {{ header.text }}
                                    <v-menu
                                        offset-y
                                        :close-on-content-click="false"
                                        top
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                small
                                                v-bind="attrs"
                                                v-on="on"
                                                @click.prevent="
                                                    onSearch('symbolSearch')
                                                "
                                            >
                                                mdi-magnify
                                            </v-icon>
                                        </template>
                                        <div
                                            style="
                                                background-color: #1e1e1e;
                                                width: 280px;
                                            "
                                        >
                                            <v-text-field
                                                v-model="search"
                                                class="px-4"
                                                type="text"
                                                ref="symbolSearch"
                                                label="Enter the search term"
                                                style="width: 100%"
                                                single-line
                                                hide-details
                                            ></v-text-field>
                                            <v-btn
                                                @click="search = ''"
                                                small
                                                text
                                                color="primary"
                                                class="ml-2 mb-2"
                                                style="padding: 6px"
                                            >
                                                Clear
                                            </v-btn>
                                        </div>
                                    </v-menu>
                                </template>
                                <template v-slot:header.net="{ header }">
                                    <v-tooltip top open-delay="500">
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <span v-bind="attrs" v-on="on">{{
                                                header.text
                                            }}</span>
                                        </template>
                                        <span
                                            >Net Volume = Long + Short (Sort by
                                            absolute value)</span
                                        >
                                    </v-tooltip>
                                </template>
                                <!-- PK表鼠标点击后的副表 -->
                                <template v-slot:item.symbol="{ item }">
                                    <v-tooltip right open-delay="500">
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <a
                                                v-bind="attrs"
                                                v-on="on"
                                                @click.stop="
                                                    () => {
                                                        getEODSymbolMaps(
                                                            item.symbol
                                                        );
                                                    }
                                                "
                                                style="
                                                    background: transparent;
                                                    padding: 0px;
                                                    color: white;
                                                "
                                            >
                                                {{ item.symbol }}</a
                                            >
                                        </template>
                                        <TooltipTable :monitor-item="item" />
                                    </v-tooltip>
                                </template>
                                <template v-slot:item.net="{ item }">
                                    {{ item.netStr }}
                                </template>
                                <template v-slot:item.dailyNet="{ item }">
                                    {{ item.dailyNetStr }}
                                </template>
                                <template v-slot:item.longPositions="{ item }">
                                    {{ item.longPositionsStr }}
                                </template>
                                <template v-slot:item.shortPositions="{ item }">
                                    {{ item.shortPositionsStr }}
                                </template>
                                <template
                                    v-slot:item.floatingProfitEOD="{ item }"
                                >
                                    <div v-bind:class="`${item.color}`">
                                        {{ item.floatingProfitEODStr }}
                                    </div>
                                </template>
                                <template
                                    v-slot:item.closedProfitEOD="{ item }"
                                >
                                    <!-- @click.stop="
                                            () => {
                                                getPNLSummary(item);
                                            }
                                        " -->
                                    <a
                                        style="
                                            background: transparent;
                                            padding: 0px;
                                            color: white;
                                        "
                                    >
                                        {{ item.closedProfitEODStr }}</a
                                    >
                                </template>
                                <template v-slot:item.closedProfit="{ item }">
                                    {{ item.closedProfitStr }}
                                </template>
                                <template v-slot:item.floatingProfit="{ item }">
                                    {{ item.floatingProfitStr }}
                                </template>
                                <template v-slot:item.symbolExposure="{ item }">
                                    {{ item.symbolExposureStr }}
                                </template>
                                <template
                                    v-slot:item.weightedAvgLongPrice="{ item }"
                                >
                                    {{ item.weightedAvgLongPriceStr }}
                                </template>
                                <template
                                    v-slot:item.weightedAvgShortPrice="{ item }"
                                >
                                    {{ item.weightedAvgShortPriceStr }}
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-col>
                    <v-col lg="4" md="4" xs="12" cols="12" class="d-flex">
                        <div class="d-flex flex-column flex-grow-1">
                            <div
                                :data-title="
                                    tutorialConstant['ib-monitor'][
                                        'pageComponent'
                                    ]['profile']['title']
                                "
                                :data-intro="
                                    tutorialConstant['ib-monitor'][
                                        'pageComponent'
                                    ]['profile']['intro']
                                "
                                :data-step="
                                    tutorialConstant['ib-monitor'][
                                        'pageComponent'
                                    ]['profile']['step']
                                "
                            >
                                <v-row>
                                    <v-col
                                        cols="8"
                                        class="pr-0"
                                        :data-title="
                                            tutorialConstant['ib-monitor'][
                                                'profileManipulation'
                                            ]['profileName']['title']
                                        "
                                        :data-intro="
                                            tutorialConstant['ib-monitor'][
                                                'profileManipulation'
                                            ]['profileName']['intro']
                                        "
                                        :data-step="
                                            tutorialConstant['ib-monitor'][
                                                'profileManipulation'
                                            ]['profileName']['step']
                                        "
                                    >
                                        <v-text-field
                                            :value="selectedProfile.text"
                                            label="Profile Name"
                                            readonly
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="4"
                                        class="pl-0"
                                        :data-title="
                                            tutorialConstant['ib-monitor'][
                                                'profileManipulation'
                                            ]['profileEdition']['title']
                                        "
                                        :data-intro="
                                            tutorialConstant['ib-monitor'][
                                                'profileManipulation'
                                            ]['profileEdition']['intro']
                                        "
                                        :data-step="
                                            tutorialConstant['ib-monitor'][
                                                'profileManipulation'
                                            ]['profileEdition']['step']
                                        "
                                    >
                                        <v-btn
                                            icon
                                            small
                                            @click="
                                                updateProfileDialog(
                                                    selectedProfile
                                                )
                                            "
                                            :disabled="
                                                !selectedProfile.id ||
                                                selectedProfile.id === 0
                                            "
                                        >
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn
                                            icon
                                            small
                                            @click="confirmPopover = true"
                                            :disabled="
                                                !selectedProfile.id ||
                                                selectedProfile.id === 0
                                            "
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <div
                                        style="width: 100%"
                                        :data-title="
                                            tutorialConstant['ib-monitor'][
                                                'profileManipulation'
                                            ]['userSalesParam']['title']
                                        "
                                        :data-intro="
                                            tutorialConstant['ib-monitor'][
                                                'profileManipulation'
                                            ]['userSalesParam']['intro']
                                        "
                                        :data-step="
                                            tutorialConstant['ib-monitor'][
                                                'profileManipulation'
                                            ]['userSalesParam']['step']
                                        "
                                    >
                                        <v-col cols="12">
                                            <v-text-field
                                                dense
                                                label="Enter IB (Separate by commas)"
                                                v-model="salesInput"
                                                :rules="salesUsersInputRules"
                                                hide-details
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                dense
                                                label="Enter Back Office ID (Separate by commas)"
                                                v-model="backOfficeInput"
                                                :rules="salesUsersInputRules"
                                                hide-details
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                dense
                                                label="Enter Customer ID (Separate by commas)"
                                                v-model="usersInput"
                                                :rules="salesUsersInputRules"
                                                hide-details
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                dense
                                                label="Enter Login (Separate by commas)"
                                                v-model="loginsInput"
                                                :rules="salesUsersInputRules"
                                                hide-details
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </div>

                                    <v-col cols="12">
                                        <v-btn
                                            @click="updateProfileParams()"
                                            color="primary"
                                            v-if="
                                                selectedProfile.text !==
                                                'Default'
                                            "
                                            :disabled="isUpdateDisabled"
                                            :loading="loading"
                                            class="mr-2"
                                        >
                                            Update Params
                                        </v-btn>
                                        <v-btn
                                            @click="saveProfileDialog()"
                                            color="primary"
                                            :disabled="allProfile.length === 0"
                                            :loading="loading"
                                            :data-title="
                                                tutorialConstant['ib-monitor'][
                                                    'profileManipulation'
                                                ]['saveAsNew']['title']
                                            "
                                            :data-intro="
                                                tutorialConstant['ib-monitor'][
                                                    'profileManipulation'
                                                ]['saveAsNew']['intro']
                                            "
                                            :data-step="
                                                tutorialConstant['ib-monitor'][
                                                    'profileManipulation'
                                                ]['saveAsNew']['step']
                                            "
                                        >
                                            Save as new
                                        </v-btn>
                                        <v-btn
                                            icon
                                            @click="revertParams()"
                                            color="primary"
                                            :disabled="isUpdateDisabled"
                                            :loading="loading"
                                            :data-title="
                                                tutorialConstant['ib-monitor'][
                                                    'profileManipulation'
                                                ]['revertParams']['title']
                                            "
                                            :data-intro="
                                                tutorialConstant['ib-monitor'][
                                                    'profileManipulation'
                                                ]['revertParams']['intro']
                                            "
                                            :data-step="
                                                tutorialConstant['ib-monitor'][
                                                    'profileManipulation'
                                                ]['revertParams']['step']
                                            "
                                        >
                                            <v-icon>mdi-undo</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                            <ExposureChart
                                ref="exposureChart"
                                style="margin-top: 10px"
                                :chartHeight="exposureChartHeight"
                                :data-title="
                                    tutorialConstant['ib-monitor'][
                                        'pageComponent'
                                    ]['exposure']['title']
                                "
                                :data-intro="
                                    tutorialConstant['ib-monitor'][
                                        'pageComponent'
                                    ]['exposure']['intro']
                                "
                                :data-step="
                                    tutorialConstant['ib-monitor'][
                                        'pageComponent'
                                    ]['exposure']['step']
                                "
                            />
                        </div>
                    </v-col>
                </v-row>

                <!-- PK monitor table modal when click realized number -->
                <v-dialog
                    v-model="dialogCloseSummaryVisible"
                    :width="dialogWidth"
                    :hide-overlay="true"
                    :persistent="true"
                >
                    <v-card>
                        <v-app-bar
                            flat
                            color="rgba(0, 0, 0, 0)"
                            style="background: rgb(26, 57, 88)"
                        >
                            <v-toolbar-title class="text-h6 white--text pl-0"
                                >Close Summary - {{ closeSummarySymbol }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="white"
                                icon
                                @click="dialogCloseSummaryVisible = false"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-app-bar>

                        <v-container>
                            <v-row>
                                <v-col cols="12" class="pt-0 pb-0 pl-0">
                                    <v-tabs>
                                        <v-tab
                                            dense
                                            v-on:click="changeTab('login')"
                                            >PNL by Login</v-tab
                                        >
                                        <v-tab
                                            dense
                                            v-on:click="changeTab('symbol')"
                                            >PNL by Symbol</v-tab
                                        >

                                        <!-- <v-tab v-on:click="changeTab('raw')"
                                            >Raw Order</v-tab
                                        > -->
                                    </v-tabs>
                                </v-col>
                            </v-row>
                            <v-row v-if="selectedKey === 'login'" class="mt-5">
                                <v-col cols="12" class="pt-0 pb-0">
                                    <v-data-table
                                        :headers="closeSummaryLoginHeaders"
                                        :items="closeSummaryLoginData"
                                        dense
                                        :header-props="{ sortIcon: null }"
                                        :search="searchCloseSummaryLogin"
                                        :items-per-page="15"
                                        :loading="tableLoading"
                                    >
                                        <template
                                            v-slot:header.login="{ header }"
                                        >
                                            {{ header.text }}
                                            <v-menu
                                                offset-y
                                                :close-on-content-click="false"
                                                top
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-icon
                                                        small
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        @click.prevent="
                                                            onSearch(
                                                                'loginSearch'
                                                            )
                                                        "
                                                    >
                                                        mdi-magnify
                                                    </v-icon>
                                                </template>
                                                <div
                                                    style="
                                                        background-color: #1e1e1e;
                                                        width: 280px;
                                                    "
                                                >
                                                    <v-text-field
                                                        v-model="
                                                            searchCloseSummaryLogin
                                                        "
                                                        class="px-4"
                                                        type="text"
                                                        ref="loginSearch"
                                                        label="Enter the search term"
                                                        style="width: 100%"
                                                        single-line
                                                        hide-details
                                                    ></v-text-field>
                                                    <v-btn
                                                        @click="
                                                            searchCloseSummaryLogin =
                                                                ''
                                                        "
                                                        small
                                                        text
                                                        color="primary"
                                                        class="ml-2 mb-2"
                                                        style="padding: 6px"
                                                    >
                                                        Clear
                                                    </v-btn>
                                                </div>
                                            </v-menu>
                                        </template>
                                        <template v-slot:item.profit="{ item }">
                                            {{
                                                numberWithCommas(
                                                    item.profit.toFixed(2)
                                                )
                                            }}
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            <v-row v-if="selectedKey === 'symbol'" class="mt-5">
                                <v-col cols="12" class="pt-0 pb-0">
                                    <v-data-table
                                        :headers="closeSummarySymbolHeaders"
                                        :items="closeSummarySymbolData"
                                        dense
                                        :header-props="{ sortIcon: null }"
                                        :items-per-page="10"
                                        :loading="tableLoading"
                                    >
                                        <template v-slot:item.profit="{ item }">
                                            {{
                                                numberWithCommas(
                                                    item.profit.toFixed(2)
                                                )
                                            }}
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            <!-- <v-row v-if="selectedKey === 'raw'" class="mt-5">
                                <v-col cols="12" class="pt-0 pb-0">
                                    <v-text-field
                                        v-model="searchCloseSummaryRaw"
                                        append-icon="mdi-magnify"
                                        label="Search"
                                        dense
                                        single-line
                                        hide-details
                                    />
                                    <v-data-table
                                        :headers="closeSummaryRawHeaders"
                                        :items="closeSummaryRawData"
                                        :search="searchCloseSummaryRaw"
                                        dense
                                        :loading="tableLoading"
                                    >
                                    </v-data-table>
                                </v-col>
                            </v-row> -->
                        </v-container>
                    </v-card>
                </v-dialog>

                <LoginSummaryDialog
                    v-if="loginSummaryHistoryVisible"
                    :fullLogin="fullLoginValue"
                    :login="loginValue"
                    :server="serverValue"
                    :symbol="symbolValue"
                />
            </v-container>
        </v-card>
        <v-dialog v-model="csvFieldsDialog" width="1000" :persistent="true">
            <v-card>
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0">
                        CSV Table Fields
                    </v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-btn color="white" icon @click="cancelDownload">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>

                <v-card-actions>
                    <v-row>
                        <v-col cols="6" style="display: flex">
                            <v-text-field
                                v-model="inputCsvName"
                                label="CSV export name"
                                :rules="inputRules"
                                hide-details
                                required
                            ></v-text-field>

                            <b
                                style="
                                    display: flex;
                                    align-items: end;
                                    height: 100%;
                                "
                                >.csv</b
                            >
                        </v-col>
                        <v-col cols="6">
                            <v-switch
                                v-model="selectAll"
                                label="Select All"
                                hide-details
                                @click="toggleSwitch"
                            >
                            </v-switch>
                        </v-col>
                        <v-col
                            cols="3"
                            v-for="item in Object.keys(selectedCSVFields)"
                        >
                            <v-checkbox
                                v-model="selectedCSVFields[item]"
                                :label="item"
                                hide-details
                                @click="checkAll"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="cancelDownload">
                        Cancel
                    </v-btn>
                    <v-btn color="primary" text @click="confirmDownload">
                        Download
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- EOD Symbol map -->
        <v-dialog
            v-model="symbolMap"
            :width="800"
            :hide-overlay="true"
            :persistent="true"
            :fullscreen="fullscreen"
        >
            <v-card>
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0"
                        >EOD Symbol Price Map - {{ selectedSymbolMap }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="white" icon @click="symbolMap = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>

                <v-container>
                    <v-row>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-data-table
                                :headers="symbolMapHeader"
                                :items="
                                    !!selectedSymbolMap
                                        ? computedSymbolPriceMapData[
                                              selectedSymbolMap
                                          ]
                                        : []
                                "
                                dense
                                :header-props="{ sortIcon: null }"
                                :items-per-page="15"
                                :mobile-breakpoint="0"
                                :footer-props="{
                                    'items-per-page-text': '',
                                }"
                                :class="{
                                    'fix-first-col': VUETIFY_BREAKPOINT.mobile,
                                }"
                            >
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
        <DeleteConfirmDialog
            :confirmPopover="confirmPopover"
            type="Profile"
            :name="selectedProfile.text"
            :loading="loading"
            @close="
                () => {
                    confirmPopover = false;
                }
            "
            @confirm="deleteProfile"
        ></DeleteConfirmDialog>
        <SymbolDetailDialog
            :visible.sync="monitorDialog"
            :symbol="currentExpanded"
            :symbolDetailArray="symbolDetailArray"
            :table-loading="monitorDialogLoading"
            :showHomeLoginDetailSummary="showIBLoginDetailSummary"
            @login-click="loadLoginSummaryHistoryDialog"
        />
        <a id="downloadAnchorElement" style="display: none"></a>
    </div>
</template>
<script>
import dayjs from "dayjs";
import md5 from "md5";
import { helper } from "@components/mixins/helper";
import { permission } from "@components/mixins/permission";
import { mapActions, mapMutations, mapState } from "vuex";
import CardHead from "@views/home/ComponentCardHead";
import ExposureChart from "@views/ib-monitor/ComponentIBExposureChart";
import common from "@assets/js/common";
import LoginSummaryDialog from "@components/login-summary/ComponentLoginSummaryDialog";
import { position } from "@components/mixins/position";
import { snackbar } from "@components/mixins/snackbar";
import deepClone from "deep-clone";
import * as Sortable from "sortablejs/Sortable.min";
import DeleteConfirmDialog from "@components/ComponentDeleteConfirmDialog";
import TooltipTable from "@views/home/ComponentTooltipTable";
import SymbolDetailDialog from "@views/home/ComponentSymbolDetailDialog";
import introJs from "intro.js";
import { TutorialConstant } from "@assets/tutorialConstant";
import { putAuthUser } from "@services/account/auth";

function watchClass(targetNode, classToWatch) {
    let lastClassState = targetNode.classList.contains(classToWatch);
    const observer = new MutationObserver((mutationsList) => {
        for (let i = 0; i < mutationsList.length; i++) {
            const mutation = mutationsList[i];
            if (
                mutation.type === "attributes" &&
                mutation.attributeName === "class"
            ) {
                const currentClassState =
                    mutation.target.classList.contains(classToWatch);
                if (lastClassState !== currentClassState) {
                    lastClassState = currentClassState;
                    if (!currentClassState) {
                        mutation.target.classList.add("sortHandle");
                    }
                }
            }
        }
    });
    observer.observe(targetNode, { attributes: true });
}

export default {
    components: {
        ExposureChart,
        LoginSummaryDialog,
        CardHead,
        DeleteConfirmDialog,
        TooltipTable,
        SymbolDetailDialog,
    },
    mixins: [helper, position, permission, snackbar],
    data() {
        return {
            tutorialConstant: TutorialConstant,
            profileDialog: {
                isOpen: false,
                mode: "",
                title: "",
                id: null,
            },
            inputRules: [
                (v) =>
                    !!v ||
                    "Please enter a new value as the name of CSV output.",
            ],
            salesUsersInputRules: [
                (v) =>
                    /^[0-9,]*$/.test(v) ||
                    "Please enter a new value as the name of CSV output.",
            ],
            csvFieldsDialog: false,
            selectedCSVFields: {},
            computedCSVFields: {},
            inputCsvName: "",
            selectAll: true,
            loginSummaryHistoryVisible: false,
            pkSocket: null,
            lockReconnect: false, // 避免 socket 重复连接
            openSymbol: "",
            confirmPopover: false,
            sortBy: "symbolExposure",
            sortDesc: true,

            expanded: [],
            pkData: [],
            loadSubTable: false,
            search: "",
            searchLogin: "",
            currentExpanded: "",
            symbolDetailObject: {},
            symbolDetailArray: [],
            dialogCloseSummaryVisible: false,
            closeSummaryLoginData: [],
            closeSummaryLoginHeaders: [
                {
                    text: "Login",
                    align: "start",
                    sortable: false,
                    value: "login",
                },
                {
                    text: "Realized(EOD)",
                    value: "realized_eod",
                    align: "end",
                    sortable: true,
                },
                {
                    text: "Realized(MT)",
                    value: "realized_mt4",
                    align: "end",
                    sortable: true,
                },
            ],
            searchCloseSummaryLogin: "",
            closeSummarySymbolData: [],
            closeSummarySymbolHeaders: [
                {
                    text: "Symbol",
                    align: "start",
                    sortable: false,
                    value: "symbol",
                },
                {
                    text: "Realized(EOD)",
                    value: "realized_eod",
                    align: "end",
                    sortable: true,
                },
                {
                    text: "Realized(MT)",
                    value: "realized_mt4",
                    align: "end",
                    sortable: true,
                },
            ],
            searchCloseSummarySymbol: "",
            closeSummaryRawData: [],
            closeSummaryRawHeaders: [
                {
                    text: "Login",
                    align: "start",
                    sortable: false,
                    value: "login",
                },
                { text: "Type", value: "type", sortable: true },
                { text: "Ticket", value: "ticket" },
                { text: "Profit", value: "profit", sortable: true },
                { text: "Open Time", value: "open_time", sortable: true },
                { text: "Close Time", value: "close_time", sortable: true },
                { text: "Open Price", value: "open_price" },
                { text: "Close Price", value: "close_price" },
                { text: "Comment", value: "comment" },
                { text: "Reason", value: "tradeReason" },
                { text: "Symbol", value: "symbol" },
                { text: "Volume", value: "volume" },
                { text: "Book", value: "book" },
            ],
            searchCloseSummaryRaw: "",
            selectedKey: "login",
            dialogWidth: "450px",
            closeSummarySymbol: "",
            fullLoginValue: "",
            loginValue: "",
            serverValue: "",
            symbolValue: "",
            isDataStreaming: null,
            tableHeight: 616,
            exposureChartHeight: 400,
            anIncreasingNumberForMain: 1,
            anIncreasingNumberForSub: 1,
            mainHeader: null,
            subHeader: null,
            allowSymbolDetailsDownload: false,
            isCsvRecording: false,
            symbolDetails: null,
            selectedDetailInterval: 30000,
            detailIntervalOptions: [
                { key: "30s", value: 30000 },
                { key: "45s", value: 45000 },
            ],
            symbolMap: false,
            selectedSymbolMap: null,
            symbolMapHeader: [
                {
                    text: "Symbol",
                    align: "start",
                    sortable: false,
                    value: "symbolName",
                },
                {
                    text: "Ask",
                    value: "ask",
                    align: "end",
                    sortable: true,
                },
                {
                    text: "Bid",
                    value: "bid",
                    align: "end",
                    sortable: true,
                },
                {
                    text: "Date",
                    value: "dateTime",
                    align: "end",
                    sortable: true,
                },
            ],
            fullscreen: false,
            selectedUsers: null,
            selectedSales: null,
            selectedBackOffice: null,
            selectedLogins: null,
            salesInput: "",
            backOfficeInput: "",
            usersInput: "",
            loginsInput: "",
            profileNameInput: "",
            recordedProfitSymbolDetailMap: null,
            monitorDialog: false,
            monitorDialogLoading: false,
            showIBLoginDetailSummary: false,
        };
    },
    computed: {
        ...mapState("IB", [
            "socketUrl",
            "detailSocketUrl",
            "mainBookTitle",
            "exposureData",
            "loading",
            "tableLoading",
            "PNLSummaryData",
            "selectedProfile",
            "updated",
            "dialogLoading",
            "allProfile",
            "startTutorial",
            "addedStatus",
        ]),
        ...mapState("Home", [
            "csvFields",
            "subTableHeader",
            "mainBookTitleDesc",
            "pkHeaders",
            "lotDigits",
        ]),
        downloadExcelName() {
            return `${this.inputCsvName.replaceAll(" ", "_")}.csv`;
        },
        computedPKData() {
            const computed = this.pkData.filter((item) =>
                item.symbol.toUpperCase().includes(this.search.toUpperCase())
            );
            return computed;
        },
        computedSymbolPriceMapData() {
            const symbolMap = {};
            this.pkData.map((item) => {
                symbolMap[item.symbol] = item._eodPriceList.map((price) => {
                    price.ask = parseFloat(price.ask).toFixed(item.digits);
                    price.bid = parseFloat(price.bid).toFixed(item.digits);
                    return price;
                });
            });
            return symbolMap;
        },
        isUpdateDisabled() {
            if (
                JSON.stringify(this.selectedProfile?.params?.sales) ===
                    JSON.stringify(
                        this.salesInput.split(",").filter((item) => item !== "")
                    ) &&
                JSON.stringify(this.selectedProfile?.params?.backOffice) ===
                    JSON.stringify(
                        this.backOfficeInput
                            .split(",")
                            .filter((item) => item !== "")
                    ) &&
                JSON.stringify(this.selectedProfile?.params?.users) ===
                    JSON.stringify(
                        this.usersInput.split(",").filter((item) => item !== "")
                    ) &&
                JSON.stringify(this.selectedProfile?.params?.users) ===
                    JSON.stringify(
                        this.loginsInput
                            .split(",")
                            .filter((item) => item !== "")
                    )
            ) {
                return true;
            }
            return false;
        },
    },
    watch: {
        updated(nv) {
            if (nv) {
                this.profileDialog.isOpen = false;
                this.getProfileAction({ meta_value: "ib" });
                this.CHANGE_UPDATE_STATUS(false);
                this.confirmPopover = false;
            }
        },
        PNLSummaryData(nv) {
            if (nv.length == 0) {
                this.closeSummaryLoginData = [];
                this.closeSummaryRawData = [];
                this.closeSummarySymbolData = [];
            } else {
                if (this.selectedKey == "login") {
                    this.closeSummaryLoginData = nv;
                } else if (this.selectedKey == "symbol") {
                    this.closeSummarySymbolData = nv;
                } else if (this.selectedKey == "raw") {
                    this.closeSummaryRawData = nv;
                } else {
                    return;
                }
            }
        },
        // 监听close summary对话框是否打开，关闭时清空图表数据
        dialogCloseSummaryVisible(nv, ov) {
            if (!nv) {
                self = this;
                self.closeSummaryLoginData = [];
                self.closeSummarySymbolData = [];
                self.closeSummaryRawData = [];
                self.searchCloseSummaryLogin = "";
                self.searchCloseSummarySymbol = "";
                self.searchCloseSummaryRaw = "";
            }
        },
        selectedProfile(nv) {
            this.UPDATE_BTN_STATUS(true);
            if (nv !== null) {
                // reset all table related data
                this.searchLogin = "";
                this.isDataStreaming = false;
                this.UPDATE_EXPOSURE_DATA({});
                this.pkData = [];
                this.UPDATE_MAIN_BOOK_TITLE({
                    unrealized: 0,
                    realized: 0,
                    dailyNet: 0,
                });
                this.expanded = [];
                this.selectedSales = nv.params?.sales;
                this.selectedBackOffice = nv.params?.backOffice;
                this.selectedUsers = nv.params?.users;
                this.selectedLogins = nv.params?.logins;
                this.salesInput = nv.params?.sales?.join(",") || "";
                this.backOfficeInput = nv.params?.backOffice?.join(",") || "";
                this.usersInput = nv.params?.users?.join(",") || "";
                this.loginsInput = nv.params?.logins?.join(",") || "";

                if (this.pkSocket) {
                    this.pkSocket.close();
                }

                if (
                    (
                        this.salesInput +
                        this.backOfficeInput +
                        this.usersInput +
                        this.loginsInput
                    ).length > 0
                ) {
                    this.snackBarInfo("Connecting to socket...", 5000);
                    this.createPKWebSocket();
                }
            }
        },
        isDataStreaming(nv) {
            if (nv === true) {
                this.UPDATE_BTN_STATUS(false);
                this.snackBarSuccess("Connection Established");
            }
        },
        startTutorial(nv) {
            if (!!nv) {
                introJs()
                    .oncomplete(() => this.UPDATE_IB_TUTORIAL_STATUS(false))
                    .onexit(() => this.UPDATE_IB_TUTORIAL_STATUS(false))
                    .start();
            }
        },
    },
    methods: {
        ...mapActions("IB", [
            "getProfileAction",
            "getPNLSummaryAction",
            "postProfileAction",
            "putProfileAction",
            "deleteProfileAction",
        ]),
        ...mapMutations("IB", [
            "UPDATE_MAIN_BOOK_TITLE",
            "UPDATE_EXPOSURE_DATA",
            "UPDATE_BTN_STATUS",
            "UPDATE_SELECTED_PROFILE",
            "CHANGE_UPDATE_STATUS",
            "UPDATE_LOADING",
            "UPDATE_IB_TUTORIAL_STATUS",
            "UPDATE_ADDED_STATUS",
        ]),
        revertParams() {
            this.salesInput = this.selectedProfile.params.sales.join(",");
            this.backOfficeInput =
                this.selectedProfile.params.backOffice.join(",");
            this.usersInput = this.selectedProfile.params.users.join(",");
            this.loginsInput = this.selectedProfile.params.logins.join(",");
        },
        getSubTableLogin(login) {
            return login.split(":")[1] + ":" + login.split(":")[2];
        },
        toggleSwitch() {
            if (!this.selectAll) {
                const copy = deepClone(this.selectedCSVFields);
                Object.keys(this.csvFields).map((item) => (copy[item] = false));
                this.selectedCSVFields = copy;
            } else {
                const copy = deepClone(this.selectedCSVFields);
                Object.keys(this.csvFields).map((item) => (copy[item] = true));
                this.selectedCSVFields = copy;
            }
        },
        checkAll() {
            let allSelected = true;
            Object.keys(this.selectedCSVFields).map((item) => {
                allSelected = allSelected && this.selectedCSVFields[item];
            });
            this.selectAll = allSelected;
        },
        startDownload() {
            this.selectAll = true;
            this.selectedCSVFields = {};
            this.isCsvRecording = true;
            Object.keys(this.csvFields).map(
                (item) => (this.selectedCSVFields[item] = true)
            );
            this.inputCsvName =
                "position ib profile - " + this.selectedProfile.text;
            this.csvFieldsDialog = true;
        },
        confirmDownload() {
            let copy = deepClone(this.csvFields);
            Object.keys(this.selectedCSVFields).map((item) => {
                if (!this.selectedCSVFields[item]) {
                    delete copy[item];
                }
            });
            this.computedCSVFields = copy;
            if (Object.keys(this.computedCSVFields).length === 0) {
                this.snackBarDanger("Please selected at least one field");
            } else {
                document.getElementById("csvExport").click();
                if (this.allowSymbolDetailsDownload && this.monitorDialog) {
                    var dataStr =
                        "data:text/json;charset=utf-8," +
                        encodeURIComponent(JSON.stringify(this.symbolDetails));
                    var dlAnchorElem = document.getElementById(
                        "downloadAnchorElement"
                    );
                    dlAnchorElem.setAttribute("href", dataStr);
                    dlAnchorElem.setAttribute("download", "SymbolDetails.json");
                    dlAnchorElem.click();
                }
            }
        },
        cancelDownload() {
            this.csvFieldsDialog = false;
        },
        async fetchData() {
            this.csvFieldsDialog = false;
            return this.computedPKData;
        },
        sortPKHeadersAndUpdateTheKey(evt) {
            const headersTmp = this.mainHeader;
            const oldIndex = evt.oldIndex;
            const newIndex = evt.newIndex;
            if (newIndex >= headersTmp.length) {
                let k = newIndex - headersTmp.length + 1;
                while (k--) {
                    headersTmp.push(undefined);
                }
            }
            headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
            localStorage.setItem("mainHeader", JSON.stringify(headersTmp));
            this.anIncreasingNumberForMain += 1;
        },
        sortSubHeadersAndUpdateTheKey(evt) {
            const headersTmp = this.subHeader;
            const oldIndex = evt.oldIndex;
            const newIndex = evt.newIndex;
            if (newIndex >= headersTmp.length) {
                let k = newIndex - headersTmp.length + 1;
                while (k--) {
                    headersTmp.push(undefined);
                }
            }
            headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
            localStorage.setItem("subHeader", JSON.stringify(headersTmp));
            this.anIncreasingNumberForSub += 1;
        },
        createPKWebSocket() {
            try {
                if ("WebSocket" in window) {
                    this.pkSocket = new WebSocket(this.socketUrl);
                }
                this.initWSEventHandle();
            } catch (e) {
                this.reconnect();
            }
        },
        initWSEventHandle() {
            // on socket message coming
            this.pkSocket.onopen = (evt) => {
                this.onWebSocketOpen(evt);
            };

            this.pkSocket.onmessage = (evt) => {
                this.onWebSocketMessage(evt);
            };

            this.pkSocket.onclose = (evt) => {
                this.reconnect();
                console.log("on close: ", evt);
            };

            this.pkSocket.onerror = (evt) => {
                this.reconnect();
                console.log("On error: ", evt);
            };
        },
        reconnect() {
            if (this.lockReconnect) return;
            this.lockReconnect = true;
            setTimeout(() => {
                if (this.pkSocket == null || this.pkSocket.readyState == 3) {
                    if (
                        this.selectedSales?.length +
                            this.selectedBackOffice?.length +
                            this.selectedLogins?.length +
                            this.selectedUsers?.length >
                        0
                    ) {
                        console.log("reconnecting...");
                        const lastSelection = this.selectedProfile;
                        this.UPDATE_SELECTED_PROFILE(null);
                        this.UPDATE_SELECTED_PROFILE(lastSelection);
                    }
                }
                this.lockReconnect = false;
            }, 1000);
        },
        setHeights() {
            const innerHeight = window.innerHeight;
            this.tableHeight =
                innerHeight - 273 > 636 ? innerHeight - 273 : 636;

            this.exposureChartHeight = innerHeight * 0.4;
            // this.exposureChartHeight =
            //         innerHeight - 130 - this.allBookTableHeight - 37 - 39 > 200
            //             ? innerHeight - 130 - this.allBookTableHeight - 37 - 39
            //             : 200;
        },
        onResize() {
            this.setHeights();
        },
        rowClicked(data) {
            if (this.monitorDialog === false) {
                if (this.currentExpanded === data.symbol) {
                    this.monitorDialog = true;
                } else {
                    this.currentExpanded = data.symbol;
                    this.symbolDetailArray = [];
                    this.$nextTick(() => {
                        this.sendExpandSymbolRequest();
                    });
                }
            } else {
                if (this.currentExpanded !== data.symbol) {
                    this.currentExpanded = data.symbol;
                    this.symbolDetailArray = [];
                    this.$nextTick(() => {
                        this.sendExpandSymbolRequest();
                    });
                }
            }
        },
        sendExpandSymbolRequest() {
            this.monitorDialog = true;
            this.monitorDialogLoading = true;
            const pullCommand = {
                externalMsgType: "CMD_PULL_IB_SYMBOL_RESULT",
                ref: "|" + this.currentExpanded,
            };
            this.pkSocket.send(JSON.stringify(pullCommand));
        },
        filterTable(value, search, item) {
            return (
                value !== null &&
                search !== null &&
                typeof value === "string" &&
                value.toString().toLowerCase().indexOf(search.toLowerCase()) !==
                    -1
            );
        },
        customSort(items, sortBy, sortDesc) {
            console.log(sortBy);
            items.sort((a, b) => {
                if (sortBy[0] === "net") {
                    if (!sortDesc[0])
                        return (
                            Math.abs(a.longPositions + a.shortPositions) -
                            Math.abs(b.longPositions + b.shortPositions)
                        );
                    if (sortDesc[0])
                        return (
                            Math.abs(b.longPositions + b.shortPositions) -
                            Math.abs(a.longPositions + a.shortPositions)
                        );
                } else if (sortBy[0] === "dailyNet") {
                    if (!sortDesc[0])
                        return (
                            a.floatingProfitEOD +
                            a.closedProfitEOD -
                            (b.floatingProfitEOD + b.closedProfitEOD)
                        );
                    if (sortDesc[0])
                        return (
                            b.floatingProfitEOD +
                            b.closedProfitEOD -
                            (a.floatingProfitEOD + a.closedProfitEOD)
                        );
                } else if (sortBy[0] === "symbol") {
                    if (!sortDesc[0]) return a.symbol.localeCompare(b.symbol);
                    if (sortDesc[0]) return b.symbol.localeCompare(a.symbol);
                } else {
                    if (!sortDesc[0]) {
                        return a[sortBy] < b[sortBy] ? -1 : 1;
                    } else {
                        return b[sortBy] < a[sortBy] ? -1 : 1;
                    }
                }
            });
            return items;
        },
        changeTab(key) {
            this.selectedKey = key;
            if (key !== "raw") {
                this.dialogWidth = "500px";
            } else {
                this.dialogWidth = "1200px";
            }

            const params = {
                symbol: this.closeSummarySymbol,
                date: common.getMT4TimeString().split(" ")[0] + " 00:00:00",
                key: this.selectedKey,
            };
            this.getPNLSummaryAction(params);
        },
        onWebSocketOpen() {
            const data = {
                token: localStorage.getItem("token"),
                externalMsgType: "CMD_AUTHORIZATION",
            };
            this.pkSocket.send(JSON.stringify(data));
        },
        onWebSocketMessage(evt) {
            let msg = evt.data;
            msg = msg.replace("$_", "");
            const ORIGIN_HASH_MSG = md5(msg);

            // decode data
            let decodedMsg = this.unzipVersion(msg);
            // console.log(decodedMsg);
            // if data is in string format, parse it
            if (typeof decodedMsg === "string")
                decodedMsg = JSON.parse(decodedMsg);
            if (decodedMsg === null) return;

            // console.log(decodedMsg);

            if (decodedMsg.code === "INVALID_PERMISSION") {
                const data = {
                    token: localStorage.getItem("token"),
                    externalMsgType: "CMD_AUTHORIZATION",
                };
                this.pkSocket.send(JSON.stringify(data));
            }
            if (
                decodedMsg.externalMsgType === "RESPONSE_AUTH" &&
                decodedMsg.code === "OK"
            ) {
                const request = {
                    ib_id: this.selectedSales.filter((item) => item != ""),
                    customer_id: this.selectedUsers.filter(
                        (item) => item != ""
                    ),
                    back_office_id: this.selectedBackOffice.filter(
                        (item) => item != ""
                    ),
                    login: this.selectedLogins.filter((item) => item != ""),
                    externalMsgType: "CMD_SUBSCRIBE_IB_USERS",
                };
                // console.log(request);

                this.pkSocket.send(JSON.stringify(request));
            }
            if (decodedMsg.externalMsgType === "DATA_PROFIT_IB_RESULT") {
                this.isDataStreaming = true;
            }
            // symbol detail
            if (decodedMsg.externalMsgType === "DATA_SYMBOL_PROFIT_IB_RESULT") {
                const matchingRef =
                    "|" + this.currentExpanded === decodedMsg?.ref;

                // console.log(this.currentExpanded, matchingRef);
                // if(true){
                if (this.currentExpanded !== "" && matchingRef) {
                    // hash code is diff then update detail data
                    if (ORIGIN_HASH_MSG !== this.symbolDetailOriginHash) {
                        if (this.isCsvRecording) {
                            this.symbolDetails =
                                decodedMsg.userProfitDetailsBySymbolHashMap;
                            this.isCsvRecording = false;
                        }
                        // console.log(decodedMsg)
                        const expandMap =
                            decodedMsg.userProfitDetailsBySymbolHashMap;
                        const digits = decodedMsg.digits;

                        const expandData = [];
                        for (const item in expandMap) {
                            // console.log(expandMap[item])
                            const {
                                longPositions,
                                shortPositions,
                                netPositions,
                                floatingProfitEOD,
                                closedProfitEOD,
                                floatingProfit,
                                login,
                                closedProfit,
                                weightedAvgLongPrice,
                                weightedAvgShortPrice,
                                exposure,
                            } = expandMap[item];
                            expandData.push({
                                fullLogin: login,
                                login:
                                    login.split(":")[1] +
                                    ":" +
                                    login.split(":")[2],
                                longPositions: this.numberWithCommas(
                                    longPositions.toFixed(this.lotDigits)
                                ),
                                shortPositions: this.numberWithCommas(
                                    shortPositions.toFixed(this.lotDigits)
                                ),
                                netPositions: this.numberWithCommas(
                                    netPositions.toFixed(this.lotDigits)
                                ),
                                dailyNet: this.numberWithCommas(
                                    (
                                        floatingProfitEOD + closedProfitEOD
                                    ).toFixed(2)
                                ),
                                floatingProfitEOD: this.numberWithCommas(
                                    floatingProfitEOD.toFixed(2)
                                ),
                                floatingProfit: this.numberWithCommas(
                                    floatingProfit.toFixed(2)
                                ),
                                closedProfitEOD: this.numberWithCommas(
                                    closedProfitEOD.toFixed(2)
                                ),
                                closedProfit: this.numberWithCommas(
                                    closedProfit.toFixed(2)
                                ),
                                // problems here
                                weightedAvgLongPrice:
                                    weightedAvgLongPrice.toFixed(digits),
                                weightedAvgShortPrice:
                                    weightedAvgShortPrice.toFixed(digits),
                                exposure: this.numberWithCommas(
                                    exposure.toFixed(2)
                                ),
                            });
                        }
                        this.symbolDetailArray = expandData;
                        this.monitorDialogLoading = false;
                        this.symbolDetailOriginHash = ORIGIN_HASH_MSG;
                    }

                    // repull expanded data
                    const pullCommand = {
                        externalMsgType: "CMD_PULL_IB_SYMBOL_RESULT",
                        ref: "|" + this.currentExpanded,
                    };
                    if (!!this.monitorDialog) {
                        setTimeout(() => {
                            this.pkSocket.send(JSON.stringify(pullCommand));
                        }, 1000);
                    } else {
                        this.currentExpanded = undefined;
                        this.symbolDetailOriginHash = undefined;
                    }
                }
            }
            // if return data is about streaming, handle data to make the table updated
            if (decodedMsg.externalMsgType === "DATA_PROFIT_IB_RESULT") {
                // console.log(decodedMsg);

                const td = decodedMsg.profitSymbolDetailMap;
                this.recordedProfitSymbolDetailMap =
                    decodedMsg.profitSymbolDetailMap;

                for (const item in td) {
                    const dataItem = td[item];
                    const _ITEM_HASH = md5(JSON.stringify(dataItem));
                    dataItem._ITEM_HASH = _ITEM_HASH;
                    const {
                        longPositions,
                        shortPositions,
                        floatingProfitEOD,
                        closedProfitEOD,
                        eodPriceMap,
                        floatingProfit,
                        closedProfit,
                        symbolExposure,
                        weightedAvgLongPrice,
                        weightedAvgShortPrice,
                        digits,
                    } = dataItem;
                    dataItem.symbol = item;

                    // process eodPriceMap
                    dataItem._eodPriceList = [];
                    for (const symbolName in eodPriceMap) {
                        const [ask, bid, dateTime] =
                            eodPriceMap[symbolName].split(",");
                        dataItem._eodPriceList.push({
                            symbolName,
                            ask,
                            bid,
                            dateTime: this.toMTTimeString(dateTime),
                        });
                    }
                    dataItem.net = this.roundDecimal(
                        longPositions - shortPositions,
                        2
                    );

                    dataItem.netStr = this.numberWithCommas(
                        this.roundDecimal(
                            longPositions - shortPositions,
                            this.lotDigits
                        )
                    );
                    dataItem.dailyNet = this.roundDecimal(
                        floatingProfitEOD + closedProfitEOD,
                        2
                    );
                    dataItem.dailyNetStr = this.numberWithCommas(
                        this.roundDecimal(
                            floatingProfitEOD + closedProfitEOD,
                            2
                        )
                    );
                    dataItem.symbolExposureStr = this.numberWithCommas(
                        symbolExposure.toFixed(2)
                    );
                    dataItem.floatingProfitStr = this.numberWithCommas(
                        floatingProfit.toFixed(2)
                    );
                    dataItem.longPositionsStr = this.numberWithCommas(
                        longPositions.toFixed(this.lotDigits)
                    );
                    dataItem.shortPositionsStr = this.numberWithCommas(
                        shortPositions.toFixed(this.lotDigits)
                    );
                    dataItem.floatingProfitEODStr = this.numberWithCommas(
                        floatingProfitEOD.toFixed(2)
                    );
                    dataItem.closedProfitEODStr = this.numberWithCommas(
                        closedProfitEOD.toFixed(2)
                    );
                    dataItem.closedProfitStr = this.numberWithCommas(
                        closedProfit.toFixed(2)
                    );
                    dataItem.weightedAvgLongPriceStr =
                        weightedAvgLongPrice.toFixed(digits);
                    dataItem.weightedAvgShortPriceStr =
                        weightedAvgShortPrice.toFixed(digits);
                    const foundIndex = this.pkData.findIndex((data) => {
                        return data.symbol === item;
                    });
                    const found = this.pkData[foundIndex];
                    if (!found) {
                        dataItem.color = "";
                        this.pkData.push(Object.freeze(dataItem));
                    } else {
                        dataItem.color =
                            found.floatingProfitEOD > floatingProfitEOD
                                ? "green"
                                : found.floatingProfitEOD === floatingProfitEOD
                                ? ""
                                : "red";
                        if (found._ITEM_HASH !== _ITEM_HASH) {
                            this.pkData.splice(
                                foundIndex,
                                1,
                                Object.freeze(dataItem)
                            );
                        }
                    }
                }

                // big three title: UNREALIZED, REALIZED, DAILY NET
                this.UPDATE_MAIN_BOOK_TITLE({
                    unrealized: decodedMsg.floatingProfitsEOD,
                    realized: decodedMsg.closedProfitsEOD,
                    dailyNet:
                        decodedMsg.floatingProfitsEOD +
                        decodedMsg.closedProfitsEOD,
                });

                // update exposure data into state
                if (
                    Object.keys(this.exposureData).length == 0 ||
                    parseInt(Date.now() / 1000) % 5 == 0
                ) {
                    this.UPDATE_EXPOSURE_DATA(decodedMsg.exposureMap);
                }
            }
        },
        onWindowFocus() {
            this.reconnect();
        },
        onSearch(field) {
            setTimeout(() => {
                this.$refs[field].focus();
            }, 200);
        },
        getPNLSummary(data) {
            this.dialogCloseSummaryVisible = true;
            this.closeSummarySymbol = data.symbol;
            const params = {
                symbol: this.closeSummarySymbol,
                date: common.getMT4TimeString().split(" ")[0] + " 00:00:00",
                key: this.selectedKey,
            };
            this.getPNLSummaryAction(params);
        },
        toMTTimeString(timestamp) {
            const utc = require("dayjs/plugin/utc");
            const timezone = require("dayjs/plugin/timezone");
            dayjs.extend(utc);
            dayjs.extend(timezone);
            const date = dayjs
                .unix(timestamp)
                .tz("UTC")
                .format("YYYY-MM-DD HH:mm:ss");
            return date;
        },
        saveProfileDialog() {
            this.profileNameInput = "";
            this.profileDialog.isOpen = true;
            this.profileDialog.title = "Add New Profile";
            this.profileDialog.mode = "Add";
            this.profileDialog.id = -1;
        },
        saveProfile() {
            const params = {
                meta_value: "ib",
                profile_name: this.profileNameInput,
                value: {
                    users: this.usersInput.split(","),
                    sales: this.salesInput.split(","),
                    backOffice: this.backOfficeInput.split(","),
                    logins: this.loginsInput.split(","),
                },
            };
            this.postProfileAction(params);
        },
        updateProfileDialog(profile) {
            this.profileNameInput = profile?.text;
            this.profileDialog.isOpen = true;
            this.profileDialog.title = "Update Profile - " + profile.text;
            this.profileDialog.mode = "update";
            this.profileDialog.id = profile.id;
        },
        updateProfileName() {
            const locatedProfile = this.allProfile.find(
                (profile) => profile.id === this.profileDialog.id
            );
            if (!!locatedProfile) {
                const params = {
                    id: this.profileDialog.id,
                    profile_name: this.profileNameInput,
                    value: locatedProfile.params,
                };
                this.putProfileAction(params);
            } else {
                this.snackBarDanger(
                    "Something went wrong, please try again",
                    2000
                );
                this.CHANGE_UPDATE_STATUS(true);
            }
        },
        updateProfileParams() {
            const params = {
                id: this.selectedProfile.id,
                profile_name: this.selectedProfile.text,
                value: {
                    users: this.usersInput.split(","),
                    sales: this.salesInput.split(","),
                    backOffice: this.backOfficeInput.split(","),
                    logins: this.loginsInput.split(","),
                },
            };
            this.putProfileAction(params);
        },
        deleteProfile() {
            const params = {
                id: this.selectedProfile.id,
            };
            this.deleteProfileAction(params);
        },
        getEODSymbolMaps(data) {
            this.symbolMap = true;
            this.selectedSymbolMap = data;
        },
        loadLoginSummaryHistoryDialog(item) {
            if (!this.showIBLoginDetailSummary) {
                return;
            }
            // split login string to get login value
            const [server, login] = item.login.split(":");
            this.fullLoginValue = item.fullLogin;
            this.loginValue = login;
            this.serverValue = server;
            this.symbolValue = this.currentExpanded;

            this.loginSummaryHistoryVisible = false;
            this.$nextTick(() => {
                this.loginSummaryHistoryVisible = true;
            });
        },
    },
    mounted() {
        this.setHeights();
        setTimeout(() => {
            if (
                !localStorage
                    .getItem("tutorial_presented")
                    .includes("ib-monitor")
            ) {
                introJs()
                    .oncomplete(function () {
                        const params = { pages: "" };
                        if (!localStorage.getItem("tutorial_presented")) {
                            params.pages = "ib-monitor";
                        } else {
                            params.pages =
                                localStorage.getItem("tutorial_presented") +
                                ",ib-monitor";
                        }
                        putAuthUser(params).then((res) => {
                            if (res.status === 200) {
                                localStorage.setItem(
                                    "tutorial_presented",
                                    params.pages
                                );
                            }
                        });
                    })
                    .onexit(function () {
                        const params = { pages: "" };
                        if (!localStorage.getItem("tutorial_presented")) {
                            params.pages = "ib-monitor";
                        } else {
                            params.pages =
                                localStorage.getItem("tutorial_presented") +
                                ",ib-monitor";
                        }
                        putAuthUser(params).then((res) => {
                            if (res.status === 200) {
                                localStorage.setItem(
                                    "tutorial_presented",
                                    params.pages
                                );
                            }
                        });
                    })
                    .start();
            }
        }, 1000);
    },
    created() {
        this.UPDATE_LOADING(true);
        this.getProfileAction({ meta_value: "ib" });
        window.addEventListener("focus", this.reconnect);

        const userFunction = this.getFunction("ibMonitor");

        if (userFunction.includes("ibLoginDetailSummary")) {
            this.showIBLoginDetailSummary = true;
        }
        if (userFunction.includes("ibExtraSymbolDetails")) {
            this.allowSymbolDetailsDownload = true;
        }

        document.addEventListener("visibilitychange", this.userFocus);

        var localSavedMainHeader = localStorage.getItem("mainHeader");
        var localSavedSubHeader = localStorage.getItem("subHeader");

        if (!!localSavedMainHeader) {
            this.mainHeader = JSON.parse(localSavedMainHeader);
        } else {
            this.mainHeader = deepClone(this.pkHeaders);
        }

        if (!!localSavedSubHeader) {
            this.subHeader = JSON.parse(localSavedSubHeader);
        } else {
            this.subHeader = deepClone(this.subTableHeader);
        }

        if (this.pkSocket) this.pkSocket.close();
    },
    destroyed() {
        if (this.pkSocket !== null) {
            this.pkSocket.close();
        }

        window.removeEventListener("focus", this.reconnect);
    },
    directives: {
        "sortable-table": {
            inserted: (el, binding) => {
                el.querySelectorAll("th").forEach((draggableEl) => {
                    // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
                    watchClass(draggableEl, "sortHandle");
                    draggableEl.classList.add("sortHandle");
                });
                Sortable.create(
                    el.querySelector("tr"),
                    binding.value
                        ? { ...binding.value, handle: ".sortHandle" }
                        : {}
                );
            },
        },
    },
};
</script>

<style scoped>
.td-up {
    background-color: #27ae60;
}

.td-down {
    background-color: #c0392b;
}

.td-alert {
    background-color: #c0392b;
}
.hover-login-tooltip:hover {
    cursor: pointer;
}
.symbol-detail:hover {
    cursor: pointer;
}

#home-table table > tbody {
    /* height: 600px; */
    overflow-y: scroll;
}
#home-table
    > div.v-data-table__wrapper
    > table
    > tbody
    > tr.v-data-table__expanded.v-data-table__expanded__content
    > td
    > div.v-data-table.v-data-table--dense.v-data-table--fixed-header.theme--dark
    > div
    > table
    > tbody
    > tr:nth-child(n) {
    height: 32px;
}
#home-table
    > div.v-data-table__wrapper
    > table
    > tbody
    > tr.v-data-table__expanded.v-data-table__expanded__content
    > td
    > div.v-data-table.v-data-table--dense.v-data-table--fixed-header.theme--dark
    > div
    > table
    > tbody {
    height: 32px;
}
.home-checkbox .v-label {
    font-size: 12px;
}
.home-checkbox .v-messages {
    display: none;
}
#global-container
    > div.home
    > div
    > div
    > div:nth-child(5)
    > div:nth-child(2)
    > div.col-md-5.col-lg-5.col-12
    > div:nth-child(2)
    > div:nth-child(n)
    > button:hover {
    background-color: #2196f3;
}
#home-table > div.v-data-table__wrapper > table > tbody > tr:nth-child(n) {
    height: 19px;
}
#home-table
    > div.v-data-table__wrapper
    > table
    > tbody
    > tr.v-data-table__expanded.v-data-table__expanded__row:nth-child(n) {
    height: 32px;
}

#home-table table > tbody::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

#home-table table > tbody::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

#home-table table > tbody::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    background-color: #555;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
}

.v-btn:not(.v-btn--round).v-size--small {
    height: 19px;
}
.v-tab {
    padding: 0 7px;
}

.tooltip-table,
.tooltip-data {
    border: 1px solid white;
    border-collapse: collapse;
}

.tooltip-data {
    padding: 0px 5px 0px 5px;
}
</style>
